import React, { useState, useRef, useEffect } from "react"
import Link, { LinkProps } from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog"
import {
  Link as RouterLink,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom"
import WarningIcon from "@mui/icons-material/Warning"
import { breadcrumbNameMap } from "../../constants/breadcrumb"
import { Alert, AlertTitle } from "@mui/material"
import useLocationLable from "../../hooks/useLocationLable"
import {
  checkIsAlphaFromConfig,
  encryptUrlParams,
  getQueryParam
} from "../../utils/helper"
import { CountService } from "../../service/count.service"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import useInventoryStatus from "src/hooks/useInventoryStatus"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useSelector } from "react-redux"
import { getConfig } from "src/redux/slices/countSlice"
import { PATH_MAP_FOR_BC } from "src/utils/constants"
import { toast } from "react-toastify"

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
)

const Page: React.FC<any> = ({
  customPath,
  page,
  curTagNumber,
  breadCrumbInfo,
  isEditTagDisabled
}) => {
  const location = useLocation() as any
  const { t } = useTranslation()
  let confirmRef: any
  const countService = new CountService()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isErr, setIsErr] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [confirmMsg, setConfirmMsg] = useState("")
  const [tagNumber, setTagNumber] = useState(curTagNumber)
  const [oldTagNumber, setOldTagNumber] = useState(curTagNumber)
  const pathnames = location.pathname.split("/").filter(x => x)
  const { locationLable, checkPlural } = useLocationLable()
  const history = useHistory()
  const { isDisable } = useInventoryStatus()
  const config: any = useSelector((state: RootState) => state.config)
  const configData: any = config?.configData
  const dispatch = useAppThunkDispatch()
  let getDrillDowns: any = getQueryParam("drilldown", true) || ""
  getDrillDowns = getDrillDowns.split("/")
  const getDrillDownsLength = getDrillDowns => {
    if (getDrillDowns.length === 1) {
      // istanbul ignore if
      if (getDrillDowns[0] === "") {
        return false
      }
    }
    return true
  }
  const isReportDrilledDown = getDrillDownsLength(getDrillDowns)
  const isDetailDrillDown =
    pathnames.length > 2 &&
    pathnames[0] == PATH_MAP_FOR_BC.DETAILS &&
    pathnames[1] == PATH_MAP_FOR_BC.DRILLDOWN
  const updateTag = async (confirm = false) => {
    let _absTagNumber
    // istanbul ignore if
    if (!checkIsAlphaFromConfig) {
      _absTagNumber = Math.abs(tagNumber)?.toString()
    } else {
      _absTagNumber = tagNumber?.trim()?.toString()
    }
    if (_absTagNumber.startsWith("0")) {
      _absTagNumber = _absTagNumber.slice(1)
    }
    if (oldTagNumber === _absTagNumber) return
    let payload: any = {
      isConfirmed: confirm,
      oldLocationvalue: oldTagNumber,
      newLocationvalue: _absTagNumber
    }
    await countService
      .updateTag(payload)
      .then((res: any) => {
        if (res?.data) {
          let rangeId: string = res.data.idRange
          setOldTagNumber(tagNumber)
          let redirectRoute = `/count/${encryptUrlParams(
            rangeId
          )}/${encryptUrlParams(_absTagNumber)}`
          history.push(redirectRoute)
        }
      })
      .catch(err => {
        console.log("----> ", err)
        if (err && err.StatusCode && err.Message) {

          if (err.StatusCode === 409) {
            setConfirmMsg(err.Message)
            openConfirmDialogue()
          } else if(err.StatusCode === 423) {
            toast.error(err.Message)
          }
        }
      })
  }
  useEffect(() => {
    if (
      !Object.keys(configData || {}).length &&
      !["cancelled", "loading"].includes(config?.status)
    ) {
      dispatch(getConfig())
    }
  }, [])
  function useOutsideAlerter(ref, confirmDialogRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          oldTagNumber === tagNumber ||
          isErr ||
          !ref.current ||
          ref.current.contains(event.target) ||
          confirmDialogRef.current
        ) {
          return
        }
        // istanbul ignore if
        if (confirmRef?.current && confirmRef.current.contains(event.target)) {
          return
        }

        updateTag()
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, tagNumber])
  }

  const wrapperRef = useRef(null)
  const confirmDialogRef = useRef(null)
  useOutsideAlerter(wrapperRef, confirmDialogRef)

  const handlechange = (e: any) => {
    let value: any = e.target.value
    if (checkIsAlphaFromConfig(configData)) {
      let regex = /^[0-9a-zA-Z]+$/
      if (value.match(regex) || value == "") {
        setTagNumber(value.trim().toString())
      }
    } else {
      setTagNumber(value.trim())
    }
    if (!value || !value.length) {
      setErrMsg(`${t(locationLable)} # ${t(Translates.cannot_be_empty)}`)
      setIsErr(true)
    } else {
      setIsErr(false)
    }
  }

  const handleKeyUp = (e: any) => {
    // setIsErr(false)
    if (e.keyCode == 13 && tagNumber && tagNumber.length && !isErr) {
      /// Enter key
      updateTag()
    }
  }

  const openConfirmDialogue = () => {
    confirmRef = confirmDialogRef
    setIsConfirmOpen(true)
  }
  const closeConfirmDialogue = () => {
    setIsConfirmOpen(false)
  }
  const callbackConfirmation = (confirm: boolean) => {
    if (confirm) {
      updateTag(true)
    } else {
      setTagNumber(oldTagNumber)
    }
    closeConfirmDialogue()
  }
  const getBreadCrumbList = () => {
    if (breadCrumbInfo && breadCrumbInfo.currentPath && breadCrumbInfo.mapObj) {
      let breadCrumbList: any[] = []
      let skipItems: any[] = []
      breadCrumbInfo.currentPath.split("/").map((bc: string, idx: number) => {
        let linkVal: string | null = !bc.includes("@")
          ? bc
          : breadCrumbInfo.custLinks[bc]
        breadCrumbList.push({
          link: linkVal,
          showText: t(breadCrumbInfo.mapObj[bc]) ?? ""
        })
        if (bc.toString().startsWith("##") && bc.toString().endsWith("##")) {
          skipItems.push(idx)
        }
      })
      if (skipItems.length) {
        skipItems.forEach(itemIndex => {
          if (breadCrumbList[itemIndex - 1] && breadCrumbList[itemIndex]) {
            breadCrumbList[itemIndex - 1].link += `/${breadCrumbList[
              itemIndex
            ].link.replaceAll("##", "")}`
          }
        })
        skipItems.forEach(itemIndex => {
          breadCrumbList.splice(itemIndex, 1)
        })
      }
      return breadCrumbList
    }
    return []
  }
  const setContentForConfirmation = () => {
    const tempText = <b>{` "${confirmMsg}"`}</b>
    return (
      <Alert severity="error" icon={<WarningIcon />} className="custom-alert">
        <AlertTitle className="custom-alert__title">
          {t(Translates.Warning)}
        </AlertTitle>
        <p>{tempText}</p>
      </Alert>
    )
  }
  const duplicatePageTagNum = () => {
    return (
      <div className="input-block">
        {t(locationLable)} {tagNumber}
        {isErr && <span className="validate-msg">{errMsg}</span>}
      </div>
    )
  }
  const inputId: string = "flex-bc-input"
  const adjustWidth = () => {
    const ele: any = document.getElementById(inputId)
    if (ele) {
      ele.style.width = ele.value.length + 2 + "ch"
    }
  }
  const renderWithTagNumber = (_page, to, _customPath, value) => {
    if (_page && _page == "DUPLICATE_TAGS_REVIEW") {
      return duplicatePageTagNum()
    } else if (
      (_page && _page == "TAG_DETAILS") ||
      _page == "AUDIT_TAG_DETAILS"
    ) {
      setTimeout(() => {
        adjustWidth()
      }, 50)
      return (
        <div className="input-block">
          {t(locationLable)}{" "}
          <input
            disabled={isEditTagDisabled || isDisable}
            type={checkIsAlphaFromConfig(configData) === false ? "number" : ""}
            onChange={e => handlechange(e)}
            onKeyUp={e => handleKeyUp(e)}
            data-testid={"bc-input"}
            id={inputId}
            maxLength={60}
            value={tagNumber}
            ref={wrapperRef}
          />
          {isErr && <span className="validate-msg">{errMsg}</span>}
        </div>
      )
    } else {
      if (_customPath) {
        return (
          <Typography color="text.primary" key={to}>
            {value.showText}
          </Typography>
        )
      } else {
        const label = breadcrumbNameMap[to]?.replace(
          "<__REPLACE_LOCATION>",
          checkPlural(2)
        )
        return (
          <Typography color="text.primary" key={to}>
            {t(label)}
          </Typography>
        )
      }
    }
  }
  const handleBreadcrumbItemClick = event => {
    history.goBack()
    event.preventDefault()
  }
  const renderBCItem = (value, toLink, to, isPreviousOverClick) => {
    if (value && value.showText) {
      if (isPreviousOverClick) {
        return (
          <LinkRouter
            data-testid="isPreviousOverClickLinkBtn"
            className="custom-breadcrumb__link"
            underline="hover"
            color="inherit"
            to={toLink}
            key={to}
            onClick={handleBreadcrumbItemClick}
          >
            {value.showText}
          </LinkRouter>
        )
      }

      return (
        <LinkRouter
          className="custom-breadcrumb__link"
          underline="hover"
          color="inherit"
          to={toLink}
          key={to}
        >
          {value.showText}
        </LinkRouter>
      )
    }
    return null
  }
  return (
    <>
      {isConfirmOpen && (
        <ConfirmDialog
          open={isConfirmOpen}
          callBack={callbackConfirmation}
          title={`${t(Translates.Edit)} ${t(locationLable)} ${t(
            Translates.Number
          )}`}
          content={setContentForConfirmation}
          custref={confirmDialogRef}
          confirmButtonText={t(Translates.CONFIRM)}
        ></ConfirmDialog>
      )}
      {!customPath && (
        <Breadcrumbs aria-label="breadcrumb">
          {pathnames.map((_value, index) => {
            const last = index === pathnames.length - 1
            const to = `/${pathnames.slice(0, index + 1).join("/")}`
            const label = breadcrumbNameMap[to]?.replace(
              "<__REPLACE_LOCATION>",
              checkPlural(2)
            )
            return last ? (
              renderWithTagNumber(page, to, customPath, _value)
            ) : (
              <LinkRouter
                className="custom-breadcrumb__link"
                underline="hover"
                color="inherit"
                to={to}
                key={to}
              >
                {t(label)}
              </LinkRouter>
            )
          })}
        </Breadcrumbs>
      )}
      {customPath && (
        <Breadcrumbs aria-label="breadcrumb">
          {getBreadCrumbList().map((value, index) => {
            let links: any[] = []
            let curp = getBreadCrumbList().map((e: any) => {
              if (e.link) links.push(e.link)
              return e.showText
            })
            const last = index === curp.length - 1
            const to = `/${curp.slice(0, index + 1).join("/")}`
            let toLink
            let isPreviousOverClick: boolean = false

            // ------------DrillDown case--------------
            if (index > 1 && !isDetailDrillDown && isReportDrilledDown) {
              toLink = links[index]
              let paramString: string = `drilldown=${getDrillDowns
                .slice(0, index - 1)
                .join("/")}&navParams=${getQueryParam(
                "navParams",
                true
              )}&parentReportName=${getQueryParam("parentReportName", true)}`
              toLink += `?${encryptUrlParams(paramString)}`
            } else if (isDetailDrillDown) {
              isPreviousOverClick = index === curp.length - 2
              let pReport = getQueryParam("parentReportName", true) || ""
              if (index === 0) {
                let paramString: string = `userPrompts=${getQueryParam(
                  "userPrompts",
                  true
                )}`
                toLink = `/reports/${encryptUrlParams(
                  parseInt(pReport.split("@")[1])
                )}?${encryptUrlParams(paramString)}`
              } else {
                let rep = getDrillDowns.find(d => d.includes(value.showText))
                toLink = `/reports/${encryptUrlParams(
                  parseInt(rep?.split("@")[1])
                )}`
                let paramString: string = `drilldown=${getDrillDowns}&navParams=${getQueryParam(
                  "navParams",
                  true
                )}&parentReportName=${getQueryParam("parentReportName", true)}`
                toLink += `?${encryptUrlParams(paramString)}`
              }
            } else {
              // encrypting report id if target page is also a report page
              if (!isNaN(parseInt(links[index]))) {
                links[index] = encryptUrlParams(parseInt(links[index]))
              }
              toLink = `/${links.slice(0, index + 1).join("/")}`
              toLink = {
                pathname: toLink,
                state: location.state
              }
              if (
                index === curp.length - 2 &&
                breadCrumbInfo.strictLinks &&
                breadCrumbInfo.strictLinks.length
              ) {
                if (breadCrumbInfo.strictLinks.includes(value.showText)) {
                  isPreviousOverClick = true
                }
              }
            }
            return last
              ? renderWithTagNumber(page, to, customPath, value)
              : renderBCItem(value, toLink, to, isPreviousOverClick)
          })}
        </Breadcrumbs>
      )}
    </>
  )
}

type breadcrumbType = {
  customPath: string
  renderedPage: string
  curTagNumber: string
  curRangeId: string
  breadCrumbInfo: any
  isEditTagDisabled: boolean
}

const RouterBreadcrumbs: React.FC<breadcrumbType> = ({
  customPath,
  renderedPage,
  curTagNumber,
  curRangeId,
  breadCrumbInfo,
  isEditTagDisabled
}) => {
  return (
    <Switch>
      <Route
        path="*"
        component={() => (
          <Page
            isEditTagDisabled={isEditTagDisabled}
            curTagNumber={curTagNumber}
            curRangeId={curRangeId}
            page={renderedPage}
            customPath={customPath}
            breadCrumbInfo={breadCrumbInfo}
          />
        )}
      />
    </Switch>
  )
}

const areEqual = (prevProps, nextProps) => {
  if (prevProps.curTagNumber && nextProps.curTagNumber) {
    let renderComp: boolean =
      prevProps.isEditTagDisabled != nextProps.isEditTagDisabled ||
      prevProps.curTagNumber != nextProps.curTagNumber
        ? false
        : true
    return renderComp
  }
  return false
}

export default React.memo(RouterBreadcrumbs, areEqual)
