import React, { useState } from "react"
import { IconButton, Tooltip } from "@mui/material"
import { decrypt, encryptUrlParams } from "../../utils/helper"
import { Worker, Viewer } from "@react-pdf-viewer/core"
import { LoaderHelper } from "src/utils/LoaderHelper"
import { Link } from "react-router-dom"
import { ITEMS, VARIANCES_STATUS, VARIANCE_STATUS } from "src/utils/constants"
import FileSearchIcon from "../../assets/images/FileSearchIcon.svg"
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined"
import CheckIcon from "@mui/icons-material/Check"
import { VarianceService } from "src/service/variance.service"
import { printPlugin } from "@react-pdf-viewer/print"

import PrintPreviewDialog from "src/components/PrintPreviewDialog/PrintPreviewDialog"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { RootState } from "src/redux/store"
import { useSelector } from "react-redux"

const previewPdf = (pdfUrl, t, printPluginInstance) => {
  const { PrintButton } = printPluginInstance
  let file = decrypt(pdfUrl)

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div
        className="keys"
        id="flex-count-print-btn"
        style={{ position: "absolute", right: "110px", top: "67px" }}
      >
        <Tooltip arrow title={t(Translates.Preview)}>
          <PrintButton />
        </Tooltip>
      </div>
      <div style={{ height: "500" }}>
        <Viewer
          fileUrl={file}
          plugins={[printPluginInstance]}
          renderLoader={() => <>{LoaderHelper()}</>}
          renderError={() => <></>}
        />
      </div>
    </Worker>
  )
}
const renderStatus = (props, t) => {
  return (
    <span
      className={`status-bg variance-progress ${
        props.value.toLowerCase() === VARIANCES_STATUS.NOTRESEARCHED ||
        (VARIANCE_STATUS.NEEDSRESEARCH && VARIANCE_STATUS.SECONDARYCOLOR)
      }
              ${
                props.value.toLowerCase() === VARIANCE_STATUS.INPROGRESS &&
                VARIANCE_STATUS.LIGHT
              }
              ${
                props.value.toLowerCase() === VARIANCE_STATUS.RESEARCHED &&
                VARIANCE_STATUS.RESEARCHED
              }`}
    >
      {props.value.toLowerCase() === VARIANCE_STATUS.RESEARCHED ? (
        <CheckIcon />
      ) : (
        <></>
      )}
      {props.value.toLowerCase() === VARIANCE_STATUS.NOTRESEARCHED
        ? t(Translates.Not_Researched)
        : t(props.value)}
    </span>
  )
}
const displaySpan = (props: any) => {
  return (
    <span
      className={`d-block text-${
        props.cell.column.Header?.toLowerCase() === ITEMS.toLowerCase()
          ? "right"
          : "left"
      }`}
    >
      {" "}
      {props.value ? props.value : "-"}
    </span>
  )
}
const renderReseachButton = (props, t) => {
  // const status = props?.cell?.row?.original?.batchStatus || ""
  // removed below to fix FLEX-12106
  // if (status?.toLowerCase() != VARIANCE_STATUS.RESEARCHED) {
  return (
    <Link
      style={{ textDecorationLine: "none" }}
      to={{
        pathname: `${
          window.location.pathname
        }/createVariance/${encryptUrlParams(
          props.cell.row.original.researchBatchId
        )}?batchID=${encryptUrlParams(
          props.cell.row.original.researchBatchId
        )}&batchNumber=${encryptUrlParams(
          props.cell.row.original.batchNumber
        )}&varianceGroup=${encryptUrlParams(
          props.cell.row.original.varianceGroup
        )}&varianceGroupName=${encryptUrlParams(
          props.cell.row.original.varianceGroupName
        )}`
      }}
    >
      <Tooltip arrow title={t(Translates.Research_Variance)}>
        <span style={{ cursor: "pointer" }}>
          <img src={FileSearchIcon} alt="search icon" />
        </span>
      </Tooltip>
    </Link>
  )
  // }
  // return null
}
const renderPrintButton = (props, printAction, printPluginInstance, t) => {
  const status = props?.cell?.row?.original?.batchStatus || ""
  if (
    status?.toLowerCase() === VARIANCE_STATUS.INPROGRESS ||
    status?.toLowerCase() === VARIANCE_STATUS.RESEARCHED
  ) {
    return (
      <Tooltip arrow title={t(Translates.Print)}>
        <IconButton
          data-testid="UVPrintActionBtn"
          className="icon-btn small"
          onClick={() =>
            printAction(
              props.cell.row.original.researchBatchId,
              printPluginInstance
            )
          }
        >
          <LocalPrintshopOutlinedIcon />
        </IconButton>
      </Tooltip>
    )
  }
  return null
}
const useVariance = () => {
  const printPluginInstance = printPlugin()

  const { t, i18n } = useTranslation()
  const varianceService = new VarianceService()
  const [pdfPreviewElement, setPDFPreviewElement] = useState("" as any)
  const [isPrint, setIsPrint] = useState(false)
  const config = useSelector((state: RootState) => state.config?.configData)
  const _columnsData = React.useMemo(
    () => [
      {
        Header: t(Translates.Batch),
        accessor: "batchNumber", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return (
            <span className="blue-text cursor-pointer">
              {" "}
              {props.cell.row.original.batchNumber}{" "}
            </span>
          )
        }
      },
      {
        Header: t(Translates.Items),
        accessor: "items", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: t(Translates.Name),
        accessor: "researchCompletedBy", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 320,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: t(Translates.Progress),
        accessor: "progress", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return displayPercentage(props)
        }
      },
      {
        Header: t(Translates.STATUS),
        accessor: "batchStatus", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return renderStatus(props, t)
        }
      },
      {
        Header: t(Translates.ACTIONS),
        accessor: "action", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return (
            <>
              {renderReseachButton(props, t)}
              {/* removed below to fix FLEX-12106 */}
              {/* {renderPrintButton(props, printAction, printPluginInstance, t)} */}
            </>
          )
        }
      }
    ],
    [i18n.language, t]
  )

  const displayPercentage = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value + "%" : "-"}
      </span>
    )
  }
  const callBackPrintPreview = _res => {
    setIsPrint(false)
    setPDFPreviewElement("")
  }

  const printAction = async (_varianceBatchId, printPluginInstance) => {
    setIsPrint(true)
    try {
      const res = await varianceService.printVarianceBatchReport(
        _varianceBatchId
      )
      if (res) {
        setPDFPreviewElement(previewPdf(res.data, t, printPluginInstance))
      }
    } catch (_error) {
      setIsPrint(false)
    }
  }

  const _printPreviewDialog = (
    <>
      {isPrint ? (
        <PrintPreviewDialog
          open={isPrint}
          callBack={callBackPrintPreview}
          content={pdfPreviewElement}
        ></PrintPreviewDialog>
      ) : null}
    </>
  )

  ///
  const getFullVBtnLabel = () => {
    return config?.FullStoreVariances &&
      config?.FullStoreVariances[0] &&
      config?.FullStoreVariances[0].Name
      ? config?.FullStoreVariances[0].Name
      : t(Translates.Full_Variance)
  }
  const getGroupedVBtnLabel = () => {
    return config?.GroupedVariances &&
      config?.GroupedVariances[0] &&
      config?.GroupedVariances[0].Name
      ? config?.GroupedVariances[0].Name
      : t(Translates.Group_Variance)
  }
  return {
    _columnsData,
    _printPreviewDialog,
    getFullVBtnLabel,
    getGroupedVBtnLabel
  }
}
export default useVariance
